<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<el-table
			    :data="list"
				:span-method="arraySpanMethod"
			    border
			    style="width: 100%">
			    <el-table-column
			      prop="nianji"
			      label="年级号"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="number"
			      label="序号"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="t_code"
				  align="center"
			      label="教师编码（饭卡号）"
				  width="90">
			    </el-table-column>
				<el-table-column
				  prop="t_name"
				  align="center"
				  label="教师姓名">
				</el-table-column>
				<el-table-column
				  prop="jaxs"
				  align="center"
				  label="教案形式">
				</el-table-column>
				<el-table-column
				  prop="janr"
				  align="center"
				  label="教案内容">
				</el-table-column>
				<el-table-column
				  prop="zycs"
				  align="center"
				  label="作业次数">
				</el-table-column>
				<el-table-column
				  prop="zypg"
				  align="center"
				  label="作业批改">
				</el-table-column>
				<el-table-column
				  prop="zyfd"
				  align="center"
				  label="作业负担">
				</el-table-column>
				<el-table-column
				  prop="jczy"
				  align="center"
				  label="纠错作业">
				</el-table-column>
				<el-table-column
				  prop="jk"
				  align="center"
				  label="监考">
				</el-table-column>
				<el-table-column
				  prop="yj"
				  align="center"
				  label="阅卷">
				</el-table-column>
				<el-table-column
				  prop="pywq"
				  align="center"
				  label="培优、挖潜">
				</el-table-column>
				<el-table-column
				  prop="dsz"
				  align="center"
				  label="导师制">
				</el-table-column>
				<el-table-column
				  prop="njb_score"
				  align="center"
				  width="120"
				  label="年级部考核分数">
				</el-table-column>
				<el-table-column
				  prop="njb_init_score"
				  align="center"
				  width="150"
				  label="年级部考核初始得分">
				</el-table-column>
				<el-table-column
				  prop="jwc_score"
				  align="center"
				  label="教务处考核最终得分（大于等于10分的都给10分，其它不变。）"
				  width="180">
				</el-table-column>
			  </el-table>
		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'jxcg',
				autoRequest: false,
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				data:{},
				list:[],
				list_length:0,
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
			this.getData()
		},
		computed:{
			
		},
		methods:{
			getData(){
				this.axios.get('/manage/'+this.preUrl+'/view',{
					params:{
						id:this.id
					}
				}).then(res=>{
					if(res.status){
						this.data = res.data
						this.list = res.data.list
						this.list_length = this.list.length
						this.list.push({nianji:'合计',number:'人数',t_code:'教学常规明细',t_name:'年级部得分合计',jaxs:'年级部考核分合计',janr:'教务处考核分合计',zycs:'',zypg:'',zyfd:'',jczy:'',jk:'',yj:'',pywq:'',dsz:'',njb_score:''})
						this.list.push({nianji:this.data.people_num,number:this.data.njb_score,t_code:this.data.njb_kh_score,t_name:this.data.jwc_kh_score,jaxs:'',janr:'',zycs:'',zypg:'',zyfd:'',jczy:'',jk:'',yj:'',pywq:'',dsz:'',njb_score:''})
						this.list.push({nianji:'年级部总分（9大高考学科总人数×8）',number:this.data.njb_score_count,t_code:'',t_name:'',jaxs:'',janr:'',zycs:'',zypg:'',zyfd:'',jczy:'',jk:'',yj:'',pywq:'',dsz:'',njb_score:''})
						this.list.push({nianji:'“得分”=组内个人考核分数÷全组考核分数合计×年级部总分',})
					}
				})
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if(rowIndex === this.list_length){
					if(columnIndex === 0){
						return [2, 3];
					}
					if(columnIndex === 2){
						return [2, 10];
					}
				}
				if(rowIndex === this.list_length+1){
					// if(columnIndex === 0){
					// 	return [1, 2];
					// }
				}
				if(rowIndex === this.list_length+2){
					if(columnIndex === 0){
						return [1, 14];
					}
					if(columnIndex === 1){
						return [1, 20];
					}
				}
				if(rowIndex === this.list_length+3){
					if(columnIndex === 0){
						return [1, 20];
					}
				}
			},
		}
	}
</script>

<style>
</style>